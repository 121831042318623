import React, { useEffect, useState } from "react";
import axios from "axios";
import { UilAngleDown } from "@iconscout/react-unicons";
import { formatDuration } from "../components/FormatDuration";
import { ThreeDots } from "react-loader-spinner";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const serviceTypesData = ["Fast", "Normal"];

function OrderForm() {
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const customer_id = localStorage.getItem("id");
  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
  const access_token = localStorage.getItem("token");
  const fastUrl = `${process.env.REACT_APP_FAST_URL}/orders.php`;
  const normalUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/orders`;
  const serviceUrl = `${process.env.REACT_APP_FAST_URL}/service.php`;

  const [quantity, setQuantity] = useState("");
  const [youtube_url, setYoutube_Url] = useState("");
  const [service, setService] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [service_type, setService_Type] = useState("Fast");

  const [videoId, setVideoId] = useState("");
  const [videoData, setVideoData] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [price, setprice] = useState("");
  const [walletBalance, setWalletBalance] = useState(null);
  const vertical = "top";
  const horizontal = "right";

  const [selectedServiceDescription, setSelectedServiceDescription] = useState("");

  const openModal = async (serviceId) => {
    const selectedService = serviceList.find((item) => item.service_id === serviceId);
    if (selectedService) {
      const serviceDescription = selectedService.id;
      setSelectedServiceDescription(serviceDescription);
    } else {
      console.error("Service not found with id:", serviceId);
    }
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = (e) => {
    e.preventDefault();
    setShowFullDescription(!showFullDescription);
  };


  const resetForm = () => {
    setService("");
    setService_Type("Fast");
    setYoutube_Url("");
    setQuantity("");
    setVideoData("");
  };

  const handleClick = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const onServiceClicked = (value, productId, item) => () => {
    console.log(item.service_id, productId);
    setServiceId(item.service_id);
    setServicePrice(item.price);
    setService(value);
    setProduct_id(productId);
    setIsOpen(false);

    openModal(item.service_id);

  };

  const [minStyle, setMinStyle] = useState({ color: 'lightgrey', fontSize: 'small' });

  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;
    setQuantity(inputValue);

    if (inputValue < 100) {
      // setError("Quantity must be at least 100");
      setMinStyle({ color: 'red', fontSize: 'small' });
    } else {
      setMinStyle({ color: 'lightgrey', fontSize: 'small' });
      // setError("");
    }
  };

  useEffect(() => {
    setprice((quantity * servicePrice).toFixed(2));
  }, [quantity, servicePrice])

  useEffect(() => {

    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("id");
        const response = await fetch(
          `${process.env.REACT_APP_FAST_URL}/payment.php?userId=${userId}`,
          {
            method: "GET",
          }
        );

        const data = await response.json();
        if (typeof data !== "object") {
          setWalletBalance(data);

        } else {
          console.error("Error fetching wallet balance:", data.error);
        }
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
      }
    };

    fetchData();

  }, []);

  const onServiceTypeClicked = (value) => () => {
    setService_Type(value);
    setIsOpen1(false);
  };

  const handleVideoUrlChange = (event) => {
    const newVideoUrl = event.target.value;
    const newVideoId = newVideoUrl.split("v=")[1]?.substring(0, 11);
    setYoutube_Url(newVideoUrl);
    setVideoId(newVideoId);
  };

  const handlePaste = async () => {
    setIsLoading(true);
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,contentDetails`;
    try {
      const response = await axios.get(apiUrl);
      setVideoData(response.data.items[0]);
      setIsLoading(false);
      setIsButtonVisible(true);
    } catch (error) {
      console.error("Failed to fetch video info", error);
    }
  };

  useEffect(() => {
    if (videoId) {
      handlePaste();
    }
  }, [videoId]);

  useEffect(() => {
    const list = async () => {
      try {
        const result = await axios.get(serviceUrl, {
          params: {
            per_page: 100,
          },
        });
        console.log(result.data);
        const activeServices = result.data.filter(
          (service) => service.status === "active"
        );

        setServiceList(activeServices);
      } catch (err) {
        console.log(err.message, "Request Failed");
      }
    };
    list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) return;

    if (walletBalance < price) {
      setOpenError(true);
      setError2("Not enough balance in the wallet.");
      return;
    }

    const userId = localStorage.getItem("id");

    // first step to check and deduct balance
    try {
      const deductionResponse = await axios.post(
        `${process.env.REACT_APP_FAST_URL}/orderpayment.php`,
        {
          userId: userId,
          amount: price,
        }
      );

      const isMoneyDeducted = deductionResponse.data.success;

      if (!isMoneyDeducted) {
        setOpenError(true);
        setError2("Failed to deduct money from the wallet.");
        return;
      }
    } catch (error) {
      console.error("Error deducting money:", error);
      setOpenError(true);
      setError2("Failed to deduct money from the wallet.");
      return;
    }

    setIsLoad(true);
    //order data fill for wordpress woocommerce
    const orderData = {
      customer_id,
      service,
      product_id,
      // link: youtube_url,
      quantity,
      meta_data: [
        {
          key: "youtube_url",
          value: youtube_url,
        },
        {
          key: "service",
          value: service,
        },
        {
          key: "service_type",
          value: service_type,
        },
        {
          key: "quantity",
          value: quantity,
        },
        {
          key: "service_id",
          value: serviceId,
        },
      ],
    };

    const headers = {};
    // const params = {};

    // params.key = fastToken; // replace with your key
    // params.action = "add"; // or whatever action you need

    headers.Authorization = `Bearer ${access_token}`;

    // order creation in topsmmm.club

    const apiUrl = service_type === "Fast" ? `${fastUrl}` : normalUrl;
    const fast_orderData = {
      action: "add",
      service: serviceId,
      link: youtube_url,
      quantity: quantity,
      userId: userId,
      amount: price,
    };
    if (service_type === "Fast") {
      axios
        .post(apiUrl, fast_orderData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(async (response) => {
          console.log("Order created successfully!", response.data);

          /// using topsmmm club order id in woocommerce

          orderData.meta_data.push({
            key: "order_id",
            value: "app" + response.data.order,
          });

          //order creation in wordpress woocommerce
          const normalOrderResponse = await axios.post(normalUrl, orderData, {
            auth: {
              username: process.env.REACT_APP_CUSTOMER_KEY,
              password: process.env.REACT_APP_CUSTOMER_SECRET,
            },
          });

          console.log(normalOrderResponse);
          setOpenSuccess(true);
          resetForm();
          setIsButtonVisible(false);
          window.location.href = '/order-history';
        })
        .catch((error) => {
          console.log("Error creating order:", error);
          setOpenError(true);
        })
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      orderData.meta_data.push({
        key: "order_id",
        value: 0,
      });
      console.log(headers);
      axios
        .post(normalUrl, orderData, { headers })
        .then((response) => {
          setOpenSuccess(true);
          resetForm();
          setIsButtonVisible(false);
        })
        .catch((error) => {
          console.log("Error creating order:", error);
          setOpenError(true);
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  return (
    <>
      <div className="flex w-full flex-col gap-2 md:flex-row px-4">
        <div className="my-2 rounded-3xl bg-gradient-to-b from-sky-300 to-purple-500 p-px">
          <div className="w-full p-8 shadow-2xl bg-white rounded-[calc(1.5rem-1px)]">
            <form onSubmit={handleSubmit}>
              <div className="flex">
                <h2 className="font-medium uppercase text-2xl text-gray-600">
                  Add New Campaign
                </h2>
              </div>
              <div className="my-4">
                <label className="text-black font-medium">
                  Select Your Service
                </label>
                <div className="inline-flex w-full">
                  <div className="w-full relative inline-flex bg-white text-gray-900 border border-gray-400 mt-2 p-1 rounded-2xl hover:border-black focus:outline-none focus:shadow-outline">
                    <a
                      className="w-full rounded-l-md px-4 py-2 cursor-pointer"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {service}
                    </a>
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="border-1 border-gray-100 text-gray-600 inline-flex h-full items-center justify-center rounded-r-md px-2"
                      >
                        <UilAngleDown size="30" />
                      </button>
                    </div>
                    {isOpen && (
                      <div className="w-full absolute top-9 right-0 z-10 mt-4 origin-top-right text-gray-900 rounded-lg border border-gray-100 bg-white shadow-lg">
                        {serviceList?.map((item, i) => (
                          <div key={i}>
                            <button
                              type="button"
                              onClick={onServiceClicked(
                                item.name,
                                item.id,
                                item
                              )}
                              className="block rounded-lg w-full text-left px-4 py-3 text-sm text-gray-900 no-underline hover:bg-gray-100"
                            >
                              {item.name}
                            </button>

                          </div>

                        ))}
                      </div>
                    )}
                  </div>
                </div>

              </div>
              {service && (
                <>
                  <label className="text-black font-medium  mt-2 mb-3">Service Description :</label>
                  {serviceList
                    .filter((service) => service.id === selectedServiceDescription)
                    .map((service) => (
                      <div key={service.id}>
                        <p className={`text-gray-500  ${showFullDescription ? 'whitespace-pre-wrap' : 'whitespace-pre-wrap'}`}>
                          <div className="font-bold mt-2 mb-1">Price: ${service.price}</div>
                          {showFullDescription
                            ? service.description
                            : service.description.slice(0, 50) + (service.description.length > 50 ? '...' : '')}
                        </p>
                        {service.description.length > 50 && (
                          <button
                            onClick={toggleDescription}
                            className="text-blue-500 underline cursor-pointer"
                          >
                            {showFullDescription ? 'Show Less' : 'Show More'}
                          </button>
                        )}
                      </div>
                    ))}
                </>
              )}
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                {/* <div>
                  <label className="text-black font-medium">Service Type</label>
                  <div className="inline-flex w-full">
                    <div className="w-full relative inline-flex bg-white text-gray-900 border border-gray-400 mt-2 p-1 rounded-2xl hover:border-black focus:outline-none focus:shadow-outline">
                      <a
                        className="w-full rounded-l-md px-4 py-2 cursor-pointer"
                        onClick={() => setIsOpen1(!isOpen1)}
                      >
                        {service_type}
                      </a>
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() => setIsOpen1(!isOpen1)}
                          className="border-1 border-gray-100 text-gray-600 inline-flex h-full items-center justify-center rounded-r-md px-2"
                        >
                          <UilAngleDown size="30" />
                        </button>
                      </div>
                      {isOpen1 && (
                        <div className="w-full absolute top-9 right-0 z-10 mt-4 origin-top-right text-gray-900 rounded-lg border border-gray-100 bg-white shadow-lg">
                          {serviceTypesData.map((item, i) => (
                            <div key={i}>
                              <button
                                type="button"
                                onClick={onServiceTypeClicked(item)}
                                className="block rounded-lg w-full text-left px-4 py-3 text-sm text-gray-900 no-underline hover:bg-gray-100"
                              >
                                {item}
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}
                <div>
                  <label className="text-black font-medium">
                    Quantity{" "}
                    <span style={minStyle}>
                      (min:100)
                    </span>
                  </label>
                  <div className=" text-rose-500">{error}</div>
                  <div className="flex items-center " >
                    <input
                      className=" bg-white text-gray-900 border border-gray-400 mt-2 p-3 rounded-2xl hover:border-black focus:outline-none focus:shadow-outline"
                      type="number"
                      placeholder=""
                      value={quantity}
                      onChange={handleQuantityChange}
                    />
                    {quantity && (
                      <div className="text-gray-600 ml-4 overflow-auto md:overflow-visible lg:overflow-visible  lg:flex-none lg:max-w-[20rem]">
                        Price: {servicePrice} &#215; {quantity} ={" "}
                        <span style={{ color: "green" }}>{price}$</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-4">
                <label className="text-black font-medium">
                  Enter your Youtube URL
                </label>
                <input
                  className="w-full bg-white text-gray-900 border border-gray-400 mt-2 p-3 rounded-2xl hover:border-red-600 focus:border-red-700 focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="https://www.youtube.com/watch-2/wertycf"
                  value={youtube_url}
                  onChange={handleVideoUrlChange}
                  onPaste={handlePaste}
                />
              </div>
              {isButtonVisible && (
                <div className="my-2 w-1/2 lg:w-1/4">
                  <button
                    className="uppercase text-sm font-bold tracking-wide p-3 rounded-lg w-full focus:outline-none focus:shadow-outline bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:bg-blue-700 transition duration-500 text-white"
                    disabled={isLoad}
                    style={{ background: 'linear-gradient(to bottom right, #267fbe, #73dff6)' }}
                  >
                    {isLoad ? (
                      <div className="  items-center pr-3">
                        <div className="inline-block animate-spin rounded-full h-6 w-6 border-t-4 border-blue-500 border-solid" ></div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              )}
              <div className=" text-rose-500">{error2}</div>
            </form>
          </div>
        </div>
        <div className="w-full mx-auto sm:max-w-md">
          {isLoading ? (
            <span className="flex items-center justify-center mt-20">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </span>
          ) : (
            <>
              {videoData && (
                <div className="mt-10">
                  <div className="flex items-center justify-center my-4">
                    <img
                      className="border border-red-700 max-w-sm rounded bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800"
                      width={400}
                      src={videoData.snippet.thumbnails.high.url}
                      alt="Video Thumbnail"
                    />

                  </div>
                  <h2 className="text-center font-medium text-xl pl-10 pr-10 pb-3">
                    {videoData.snippet.title}
                  </h2>
                  <h2 className="text-center font-medium text-xl text-red-500">
                    {formatDuration(videoData.contentDetails.duration)}
                  </h2>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "5%" }}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClick}
            severity="success"
            sx={{ width: "100%" }}
          >
            Order created successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClick} severity="error" sx={{ width: "100%" }}>
            Error creating order.
          </Alert>
        </Snackbar>
      </Stack>

    </>
  );
}

export default OrderForm;
