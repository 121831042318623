// Sidebar imports
import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilSignOutAlt,
  UilFileCheck,
  UilShoppingBag,
  UilClock
} from "@iconscout/react-unicons";
import React, { useEffect, useState } from "react";
// Analytics Cards imports
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";
// Importing the keyboard module from @testing-library/user-event
import { keyboard } from "@testing-library/user-event";

// import { keyboard } from "@testing-library/user-event/dist/keyboard";

// // Recent Card Imports
// // import img1 from "../imgs/img1.png";
// // import img2 from "../imgs/img2.png";
// // import img3 from "../imgs/img3.png";



// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Dashboard",
    route: "/dashboard",
  },
  {
    icon: UilClipboardAlt,
    heading: "New Order",
    route: "/order/new",
  },
  {
    icon: UilPackage,
    heading: "Order History",
    route: "/order-history",
  },
  {
    icon: UilFileCheck,
    heading: "Users",
    route: "/users",
    condition: true,
  },
  {
    icon: UilChart,
    heading: "Services",
    route: "/services",
    condition: true,
  },
  {
    icon: UilUsersAlt,
    heading: "Support",
    route: "/support",
  },
  {
    icon: UilMoneyWithdrawal, // Use an appropriate icon for payment
    heading: "Payment",
    route: "/payment",
  },
  // {
  //   icon: UilSignOutAlt,
  //   heading: "Logout",
  // },
];


const totalOrders = localStorage.getItem("totalOrders");
const pendingOrders = localStorage.getItem("pendingOrdersCount");

// Analytics Cards Data
export const cardsData = [
  {
    title: "Orders",
    subtitle: "Total Orders",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: totalOrders ? totalOrders : 0,
    value: totalOrders ? totalOrders : 0,
    png: UilShoppingBag,
    // series: [
    //   {
    //     name: "Sales",
    //     data: [31, 40, 28, 51, 42, 109, 100],
    //   },
    // ],
  },



  {
    title: "Pending",
    subtitle: "Total Pending",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: pendingOrders ? pendingOrders : 0,
    value: pendingOrders ? pendingOrders : 0,
    png: UilClock,
    // series: [
    //   {
    //     name: "Revenue",
    //     data: [10, 100, 50, 70, 80, 30, 40],
    //   },
    // ],
  },
  // {
  //   title: "Expenses",
  //   color: {
  //     backGround:
  //       "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
  //     boxShadow: "0px 10px 20px 0px #F9D59B",
  //   },
  //   barValue: 60,
  //   value: "4,270",
  //   png: UilClipboardAlt,
  //   series: [
  //     {
  //       name: "Expenses",
  //       data: [10, 25, 15, 30, 12, 15, 20],
  //     },
  //   ],
  // },
];

// // Recent Update Card Data
// export const UpdatesData = [
//   {
//     img: img1,
//     name: "Andrew Thomas",
//     noti: "has ordered Apple smart watch 2500mh battery.",
//     time: "25 seconds ago",
//   },
//   {
//     img: img2,
//     name: "James Bond",
//     noti: "has received Samsung gadget for charging battery.",
//     time: "30 minutes ago",
//   },
//   {
//     img: img3,
//     name: "Iron Man",
//     noti: "has ordered Apple smart watch, samsung Gear 2500mh battery.",
//     time: "2 hours ago",
//   },
// ];
