import React, { useState, useEffect } from "react";
import { UilUserCircle } from "@iconscout/react-unicons";
import { UilAngleDown } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import wallet from './wallet3.png'

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(null);
  const isAdmin = localStorage.getItem("username") === process.env.REACT_APP_ADMIN;
  const username = localStorage.getItem("username");

  const usernameDisplay = window.innerWidth <= 768 ? { display: 'none' } : {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("id");
        const response = await fetch(`${process.env.REACT_APP_FAST_URL}/payment.php?userId=${userId}`, {
          method: "GET",
        });

        const data = await response.json();
        console.log(data);
        if (typeof (data) !== 'object') {
          setWalletBalance(data);
        }
        else {
          console.error("Error fetching wallet balance:", data.error);
        }
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
      }
    };

    fetchData();
  }, []);


  const handleProfileOpen = () => {
    setIsOpen(!isOpen);
  };

  const navigateToSettings = () => {
    navigate("/settings");
    handleProfileOpen();
  };

  const navigateToMyProfile = () => {
    navigate("/my-profile");
    handleProfileOpen();
  };

  return (
    <nav className="flex items-center relative justify-between px-5 py-6 w-full">

      <div></div>
      <div className="flex gap-2 items-center">
        <div className=" h-11 w-11 ">
          <img className="wallet-icon" src={wallet} alt="Wallet" />

        </div>
        <span className="font-bold text-xl" style={{ color: 'rgb(39, 168, 87)' }}>{walletBalance !== null ? `${walletBalance}$` : '0'}</span>

        <div
          // className="h-10 w-10 hover:ring-4 user cursor-pointer relative ring-blue-700/30 rounded-full bg-cover bg-center bg-[url('https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80')]"
          // className="h-10 w-10 hover:ring-4 user cursor-pointer relative ring-blue-700/30 rounded-full bg-cover bg-center"

          onClick={handleProfileOpen}
        >
          {isOpen && (
            <div className="drop-down z-10  w-48 overflow-hidden bg-white rounded-md shadow absolute right-3" style={{ top: '4.50rem' }}>
              <ul>
                {isAdmin && (
                  <li
                    className="px-3 py-3 text-sm font-medium flex items-center space-x-2 hover:bg-slate-200"
                    onClick={navigateToSettings}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                    <span> Setting </span>
                  </li>
                )}
                <li
                  className="px-3 py-3 text-sm font-medium flex items-center space-x-2 hover:bg-slate-200"
                  onClick={navigateToMyProfile}
                >
                  <span>
                    <UilUserCircle />
                  </span>
                  <span> My Profile </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="flex px-2 cursor-pointer" onClick={handleProfileOpen} style={{ border: '2px solid lightgrey', borderRadius: '19px' }}>
          <span className="text-center py-2 px-1" style={usernameDisplay}>Hi {username}</span><UilAngleDown size="40" />
        </div>

      </div>
    </nav >
  );
}
