import React from "react";
import Table, {
  // AvatarCell,
  // SelectColumnFilter,
  StatusPill,
} from "./overview/Table";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";

function OrderTable() {
  const fastUrl = `${process.env.REACT_APP_FAST_URL}/orders.php`;
  const access_token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const normalAdminUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/orders`;
  const normalUserUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/orders?customer=${id}`;
  const { pageSize, pageNum } = useParams();
  const [perPage, setPerPage] = useState(pageSize || 10);
  const [pageCount, setPageCount] = useState();
  const [page, setPage] = useState(parseInt(pageNum, 10) + 1 || 1);
  const [error, setError] = useState(null);
  const [normalDataList, setNormalDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fastLoaded, setFastLoaded] = useState(false);
  const [data, setData] = useState();
  const normalUrl =
    localStorage.getItem("username") === process.env.REACT_APP_ADMIN
      ? normalAdminUrl
      : normalUserUrl;

  const normal = async () => {
    try {
      if (!pageCount) {
        // Get the total count of orders without pagination
        const totalCountResponse = await axios.get(normalUrl, {
          auth: {
            username: process.env.REACT_APP_CUSTOMER_KEY,
            password: process.env.REACT_APP_CUSTOMER_SECRET,
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        setPageCount(totalCountResponse.data.length);

        // const pendingOrdersCount = totalCountResponse.data.reduce(
        //   (count, order) => (order.status === 'pending' ? count + 1 : count),
        //   0
        // );
        // localStorage.setItem("pendingOrdersCount", pendingOrdersCount);
        // console.log(pendingOrdersCount);
        localStorage.setItem("totalOrders", totalCountResponse.data.length);
      };


      const result = await axios.get(normalUrl, {
        params: {
          per_page: perPage,
          page: page,
        },
        auth: {
          username: process.env.REACT_APP_CUSTOMER_KEY,
          password: process.env.REACT_APP_CUSTOMER_SECRET,
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      // console.log(result);
      setNormalDataList(result.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Error Fetching Data");
      setIsLoading(false);
    }
  };

  const fast = async () => {
    try {
      //filter fast data
      const fastOrders = normalDataList.filter((order) =>
        order.meta_data.some(
          (meta) => meta.key === "service_type" && meta.value === "Fast"
        )
      );

      // take order ids
      const orderIDs = fastOrders
        .map((order) => {
          const orderIdMeta = order.meta_data.find(
            (meta) => meta.key === "order_id"
          );
          console.log(orderIdMeta);
          return orderIdMeta ? +orderIdMeta.value : null;
        })
        .filter((orderId) => orderId !== null);

      const orderIdsString = orderIDs.join(",");

      // Create data to be sent to the PHP script
      const requestData = {
        action: "status",
        orders: orderIdsString,
      };

      // Make a POST request to the PHP script
      const response = await axios.post(fastUrl, requestData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Parse the response data from the PHP script
      const fastOrderInfo = response.data;


      const updatedDataList = normalDataList.map((order) => {
        const orderIdMeta = order.meta_data.find(
          (meta) => meta.key === "order_id"
        );
        if (orderIdMeta) {
          const orderId = orderIdMeta.value;
          if (fastOrderInfo[orderId]) {
            order.start_count = fastOrderInfo[orderId].start_count;
            order.remains = fastOrderInfo[orderId].remains;
            return { ...order, status: fastOrderInfo[orderId].status };
          }
        }
        return order;
      });

      setNormalDataList(updatedDataList);
      // setFastDataList(result.data);
      setIsLoading(false);
      setFastLoaded(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    setPerPage(pageSize);
    if (pageSize != perPage) {
      setPage(1);
    }
  }, [pageSize]);

  useEffect(() => {
    setPage(parseInt(pageNum, 10) + 1);
  }, [pageNum]);

  useEffect(() => {
    normal();
    setPage(page);
  }, [perPage, page]);

  useEffect(() => {
    if (normalDataList.length > 0 && !fastLoaded) {
      fast();
    }
    console.log(normalDataList);

    // const pendingOrdersCount = normalDataList.reduce(
    //   (count, order) => (order.status === 'pending' ? count + 1 : count),
    //   0
    // );
    // localStorage.setItem("pendingOrdersCount", pendingOrdersCount);
    // console.log("pending:" + pendingOrdersCount);

    const temp = getData();
    setData(temp);
  }, [normalDataList, perPage, page]);

  const getData = () => {
    let pendingOrdersCount = 0;

    const mapData = (list, type) => {
      if (Array.isArray(list)) {
        return list?.map((item, index) => {
          let order = {
            id: item.meta_data[0]?.id,
            start_count: item?.start_count,
            remains: item?.remains,
            status: item.status,
            link: item.meta_data[0]?.value,
            date_created: item.date_created.split("T")[0],
            service: item.meta_data[1]?.value,
          };
          //find if the order is fast order
          // const orderIdMeta = order.meta_data.find(
          //   (meta) => meta.key === "order_id"
          // );
          // // change the id with topsmmm id
          // if (orderIdMeta) {
          //   order.id = orderIdMeta;
          // }

          if (order.status === 'pending') {
            pendingOrdersCount++;
            localStorage.setItem('pendingOrdersCount', pendingOrdersCount)
            console.log(pendingOrdersCount);
          }

          return order;
        });
      }
    };

    const mappedNormalData = mapData(normalDataList);

    return mappedNormalData;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Start Count",
        accessor: "start_count",
      },
      {
        Header: "Remaining",
        accessor: "remains",
      },
      {
        Header: "Service",
        accessor: "service",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
      },
      {
        Header: "Link",
        accessor: "link",
      },
      {
        Header: "Date Created",
        accessor: "date_created",
      },
    ],
    []
  );

  return (
    <div className="text-gray-900">
      <main className="w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <div className="">
          <h2 className="text-2xl text-gray-600 font-semibold">
            Order History
          </h2>
        </div>
        {isLoading ? (
          // Loading spinner
          <span className="flex items-center justify-center mt-20">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </span>
        ) : error ? (
          <div className="mt-8 max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-md shadow-sm text-center">
            <h1 className="text-3xl font-semibold text-600">Error</h1>
            <p className="mt-4 text-gray-600">{error}</p>
            <button
              className="mt-6 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white font-semibold py-2 px-4 rounded transition-all duration-300"
              onClick={() => {
                setError(null);
                setIsLoading(true);
                normal(); // Retry fetching data on button click
              }}
            >
              Retry
            </button>
          </div>
        ) : data.length > 0 ? (
          // Display data table
          <div className="mt-4">
            <Table columns={columns} data={data} pageCountno={pageCount} />
          </div>
        ) : (
          // Display message when no data found
          <p className="mt-4 text-gray-600 text-2xl ">Data Not Found</p>
        )}
      </main>
    </div>
  );
}

export default OrderTable;
