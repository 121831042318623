import React, { useState } from "react";
import "./Sidebar.css";
// import Logo from "../imgs/logo.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const location = useLocation();

  const [expanded, setExpaned] = useState(false);

  const sidebarVariants = {
    true: {
      left: "0",
      paddingTop: "0",
    },
    false: {
      left: "-60%",
    },
  };
  console.log(window.innerWidth);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    localStorage.removeItem("totalOrders");
    localStorage.removeItem("pendingOrdersCount");
    window.location.href = "/";
  };

  const isAdmin = localStorage.getItem("username") === process.env.REACT_APP_ADMIN;

  const logoStyle = window.innerWidth <= 768 ? { marginTop: "2rem", position: 'relative', left: '40px' } : { marginLeft: '42px' };

  return (
    <>
      {/* <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div> */}
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        <div
          className="bars"
          style={expanded ? { left: "3%" } : { left: "5%" }}
          onClick={() => setExpaned(!expanded)}
        >
          <UilBars />
        </div>
        {/* logo */}
        <div className="logo" style={logoStyle}>
          {/* <img src={Logo} alt="logo" /> */}
          <img src={require("./logoleno.png")} style={{ width: "auto", height: '4rem' }} alt="logo" />
          {/* <span>
            LE<span>NOS</span>
          </span> */}
        </div>
        <div className="menu">
          {SidebarData.map((item, index) => {
            const isActive =
              (location.pathname === "/service/new" &&
                item.route === "/services") ||
              location.pathname === item.route;
            const shouldDisplayItem =
              !item.condition || (item.condition && isAdmin);


            if (item.heading === "Payment" && isAdmin) {
              return null;
            }

            if (shouldDisplayItem) {
              return (
                <Link
                  to={item.route || "/"}
                  className={isActive ? "menuItem active" : "menuItem"}
                  key={index}
                  onClick={() => setSelected(index)}
                >
                  <item.icon />
                  <span>{item.heading}</span>
                </Link>
              );
            }

            return null;
          })}


          {/* signoutIcon */}
          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt />
            <span >Logout</span>
            {/* <UilSignOutAlt /> */}
          </div>
        </div>

      </motion.div >
    </>
  );
};

export default Sidebar;
