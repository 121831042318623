import React from "react";
import { useState } from "react";

function Support() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  return (
    <div className="flex flex-col h-fit justify-center w-full px-10 lg:flex-row mb-10 mt-5">
      <div className="relative z-10 w-full max-w-2xl mt-10 lg:mt-0 lg:w-7/12 rounded-3xl  p-px  bg-gradient-to-b from-sky-300 to-purple-500 ">
        <div className="relative z-10 flex flex-col items-start justify-start p-10 bg-white rounded-[calc(1.5rem-1px)] shadow-2xl">
          {!isSubmitted && (
            <h4 className="w-full text-2xl font-medium leading-snug">
              Support - We're here to Help
            </h4>
          )}
          {!isSubmitted ? (
            <form className="relative w-full mt-6 space-y-8" onSubmit={handleSubmit}>
              <div className="relative">
                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                  Email Address
                </label>
                <input
                  type="text"
                  className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                  placeholder="janedoe@email.com"
                />
              </div>
              <div className="relative">
                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                  Subject
                </label>
                <input
                  type="text"
                  className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                  placeholder="Subject"
                />
              </div>
              <div className="relative">
                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                  Order Id
                </label>
                <input
                  type="text"
                  className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                  placeholder="Order Id"
                />
              </div>
              <div className="relative">
                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                  Message
                </label>
                <textarea
                  type="text"
                  className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                  placeholder="Write Message..."
                />
              </div>
              <div className="relative">
                <button className="inline-block w-100px px-5 py-3 text-md font-medium text-center bg-blue-500 hover:bg-blue-600 active:bg-blue-800 focus:bg-blue-700 transition duration-500 text-white rounded-lg ease" style={{ background: 'linear-gradient(to bottom right, #267fbe, #73dff6)' }}>
                  Submit
                </button>
              </div>
            </form>
          ) : (
            <div className="w-full text-2xl font-medium leading-snug">
              Thank you! We received your message. You will get a reply to your email within the day.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Support;
