// Settings.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Snackbar, Stack } from "@mui/material";

const Settings = () => {
    const [apiKey, setApiKey] = useState("");
    const [cashback, setCashback] = useState("");
    const [openError, setOpenError] = useState(false);
    const vertical = "top";
    const horizontal = "right";

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_FAST_URL}/settings.php`).then((response) => {
            console.log(response);
            console.log(response.data.cashback);
            setApiKey(response.data.apiKey || "");
            setCashback(response.data.cashback || 0);
        }).catch(e => {
            setOpenError(true)
            console.log(e)
        })
    }, []);

    const handleApiKeyChange = (event) => {
        setApiKey(event.target.value);
    };

    const handleCashbackChange = (event) => {
        setCashback(parseFloat(event.target.value) || 0);
    };

    const handleSaveSettings = () => {
        axios.post(`${process.env.REACT_APP_FAST_URL}/settings.php`, { apiKey, cashback }).then(() => {
            console.log("Settings saved:", { apiKey, cashback });
            alert('Settings Saved!');
        });
    };

    return (
        <div className="p-4 max-w-xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Settings</h2>
            <div className="mb-4">
                <label htmlFor="apiKey" className="block text-sm font-medium text-gray-600">
                    API Key:
                </label>
                <input
                    type="text"
                    id="apiKey"
                    value={apiKey}
                    onChange={handleApiKeyChange}
                    placeholder="Enter your API key"
                    className="mt-1 p-2 block w-full border rounded-md focus:outline-none focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cashback" className="block text-sm font-medium text-gray-600">
                    Cashback (%):
                </label>
                <input
                    type="text"
                    id="cashback"
                    value={cashback}
                    onChange={handleCashbackChange}
                    placeholder="Enter cashback value"
                    className="mt-1 p-2 block w-full border rounded-md focus:outline-none focus:border-blue-500"
                />
            </div>
            <button
                onClick={handleSaveSettings}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
                Save Settings
            </button>
            <Stack spacing={2} sx={{ width: "5%" }}>
                <Snackbar
                    open={openError}
                    autoHideDuration={6000}
                    //   onClose={handleClick}
                    anchorOrigin={{ vertical, horizontal }}
                    key={vertical + horizontal}
                >
                    <Alert /* onClose={handleClick} */ severity="error" sx={{ width: "100%" }}>
                        Login failed. Please check your credentials.
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    );
};

export default Settings;
