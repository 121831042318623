import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { generateBasicAuthHeader } from "../generateAuth";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Signup() {
  const access_token = process.env.REACT_APP_NORMAL_ACCESS_TOKEN;
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/custom-plugin/v1/register/`;
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const vertical = "top";
  const horizontal = "right";

  const resetForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
  };

  const handleClick = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      username,
      email,
      password,
    };

    axios
      .post(apiUrl, userData, {
        headers: {
          Authorization: `Basic ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setOpenSuccess(true);
        resetForm();
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        console.log("Error creating user:", error);
        setOpenError(true);
      });
  };

  return (
    <>
      <div className="flex min-h-screen items-center justify-center p-12">
        <form onSubmit={handleSubmit}>
          <div className="max-w-sm rounded-3xl bg-gradient-to-b from-sky-300 to-purple-500 p-px">
            <div className="rounded-[calc(1.5rem-1px)] bg-white px-10 p-12">
              <img src={require("../components/logoleno.png")} style={{ width: "auto", height: '4rem' }} alt="logo" />
              <div>
                <h1 className="text-xl font-semibold text-gray-800">
                  Sign up for an account
                </h1>
                <p className="text-sm tracking-wide text-gray-600">
                  Already have an account ?{" "}
                  <a
                    href="/"
                    className="text-blue-600 transition duration-200 hover:underline"
                  >
                    Sign in
                  </a>
                </p>
              </div>

              <div className="mt-8 space-y-8">
                <div className="space-y-6">
                  <input
                    className="w-full bg-transparent text-gray-600 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-600 invalid:border-red-500"
                    placeholder="Your Username"
                    type="text"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  <input
                    className="w-full bg-transparent text-gray-600 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-600 invalid:border-red-500"
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <input
                    className="w-full bg-transparent text-gray-600 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-600 invalid:border-red-500"
                    placeholder="Your Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <button className="h-9 px-3 w-full bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:bg-blue-700 transition duration-500 rounded-md text-white">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Stack spacing={2} sx={{ width: "5%" }}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClick}
            severity="success"
            sx={{ width: "100%" }}
          >
            User created successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClick} severity="error" sx={{ width: "100%" }}>
            Error creating user
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Signup;
