import React from "react";
import Table, {
  AvatarCell,
  SelectColumnFilter,
  StatusPill,
} from "./overview/Table";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";

function UserTable() {
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wp/v2/users`;

  const [userDataList, setUserDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const access_token = localStorage.getItem("token");

  const users = async () => {
    try {
      const result = await axios.get(apiUrl, {
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${access_token}`,

        },
      });
      console.log(result);
      setUserDataList(result.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    users();
  }, []);



  const getData = () => {
    const mapData = (list) => {
      return list.map((item, index) => ({
        id: index,
        id: item.id,
        name: item.name,
        slug: item.slug,
        description: item.description,
      }));
    };

    const mappedData = mapData(userDataList);

    return mappedData;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      //   {
      //     Header: "Status",
      //     accessor: "status",
      //     Cell: StatusPill,
      //   },
      {
        Header: "Description",
        accessor: "description",
      },
      //   {
      //     Header: "Currency",
      //     accessor: "currency",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes",
      //   },
    ],
    []
  );

  const data = React.useMemo(() => getData(), [userDataList]);

  return (
    <div className="text-gray-900">
      <main className="w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <div className="">
          <h2 className="text-2xl text-gray-600 font-semibold">
            User History
          </h2>
        </div>
        {isLoading ? (
          <span className="flex items-center justify-center mt-20">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </span>
        ) : data.length > 0 ? (
          <div className="mt-4">
            <Table columns={columns} data={data} />
          </div>
        ) : (
          <p className="mt-4 text-gray-600 font-medium">Data Not Found</p>
        )}
      </main>
    </div>
  );
}

export default UserTable;
