import React, { useState } from "react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AdminLogin() {
  // const access_token = process.env.REACT_APP_NORMAL_ACCESS_TOKEN;
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/jwt-auth/v1/token`;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const vertical = "top";
  const horizontal = "right";


  const resetForm = () => {
    setUsername("");
    setPassword("");
  };

  const handleClick = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const userData = {
      username,
      password,
    };


    axios
      .post(apiUrl, userData
        , {
          // headers: {
          //   'Content-Type': 'application/x-www-form-urlencoded',

          // },
        }
      )
      .then((response) => {
        setOpenSuccess(true);
        console.log("Login user:", response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", response.data.user_nicename);
        localStorage.setItem("email", response.data.user_email);
        localStorage.setItem("pass", password);


        resetForm();
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1500);
      })
      .catch((error) => {
        console.log("Error login user:", error);
        setOpenError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });


  };

  return (
    <>
      <div className="flex min-h-screen items-center justify-center p-12">
        <form onSubmit={handleSubmit}>
          <div className="max-w-sm rounded-3xl bg-gradient-to-b from-sky-300 to-purple-500 p-px">
            <div className="rounded-[calc(1.5rem-1px)] bg-white px-10 p-12">
              <img src={require("../components/logoleno.png")} style={{ width: "auto", height: '4rem' }} alt="logo" />
              <div>
                <h1 className="text-xl font-semibold text-gray-800">
                  Sign In to Admin account
                </h1>
                {/* <p className="text-sm tracking-wide text-gray-600">
                  Don't have an account ?{" "}
                  <a
                    href="/signup"
                    className="text-blue-600 transition duration-200 hover:underline"
                  >
                    Signup
                  </a>{" "}
                  for free
                </p> */}
              </div>

              <div className="mt-8 space-y-8">
                <div className="space-y-6">
                  <input
                    className="w-full bg-transparent text-gray-600 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-600 invalid:border-red-500"
                    placeholder="Your Username"
                    type="text"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  <input
                    className="w-full bg-transparent text-gray-600 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-600 invalid:border-red-500"
                    placeholder="Your Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <button
                  className="h-9 px-3 w-full bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:bg-blue-700 transition duration-500 rounded-md text-white"
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    <div className="  items-center pr-3">
                      <div className="inline-block animate-spin rounded-full h-6 w-6 border-t-4 border-blue-500 border-solid"></div>
                    </div>
                  ) : (
                    "Sign In"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Stack spacing={2} sx={{ width: "5%" }}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClick}
            severity="success"
            sx={{ width: "100%" }}
          >
            Admin login successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClick} severity="error" sx={{ width: "100%" }}>
            Login failed. Please check your credentials.
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default AdminLogin;
