import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UilCheckCircle } from "@iconscout/react-unicons";
import axios from "axios";


const Success = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [cashback, setCashback] = useState("");
    const [iscashback, setiscashback] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const amountpaid = queryParams.get("amount");
    const paymentDetails = {
        amount: amountpaid,
        payer: localStorage.getItem("username"),
        date: new Date().toLocaleDateString(),
        modeOfPayment: "Online",
        transactionStatus: "Success",

    };

    useEffect(() => {
        if (!location.search.includes("amount")) {
            navigate("/error");
        }

        // get cashback value
        axios.get(`${process.env.REACT_APP_FAST_URL}/settings.php?type=cashback`).then((response) => {
            console.log(response);
            console.log(response.data.cashback);
            if (response.data.cashback > 0) {
                setiscashback(true);
                setCashback((response.data.cashback * (amountpaid / 100)).toFixed(2));
            }
        }).catch(e => {
            console.log(e)
        })
    }, [location.search, navigate]);




    return (
        <div className="p-4 max-w-xl mx-auto text-center" >
            <UilCheckCircle size="72" className="text-green-600 mb-4" style={{ display: '-webkit-inline-flex' }} />

            <h2 className="text-2xl font-bold mb-6 text-green-600">
                Recharge Successful
            </h2>

            <div className="bg-white p-4 rounded-md shadow-md text-left">
                <div className="flex items-center mb-4">
                    <div className="flex-shrink-0">
                        <label className="block text-sm font-medium text-600 " style={{ color: 'gray' }}>Date:</label>
                    </div>
                    <div className="ml-auto">
                        <div className="mt-1" >
                            {paymentDetails.date}
                        </div>
                    </div>
                </div>

                <hr className="my-4 border-t border-gray-300" />

                <div className="flex items-center mb-4">
                    <div className="flex-shrink-0">
                        <label className="block text-sm font-medium text-600" style={{ color: 'gray' }}>Mode of Payment:</label>
                    </div>
                    <div className="ml-auto">
                        <div className="mt-1" >
                            {paymentDetails.modeOfPayment}
                        </div>
                    </div>
                </div>

                <hr className="my-4 border-t border-gray-300" />

                <div className="flex items-center mb-4">
                    <div className="flex-shrink-0">
                        <label className="block text-sm font-medium text-600" style={{ color: 'gray' }}>Transaction Status:</label>
                    </div>
                    <div className="ml-auto">
                        <div className="mt-1" style={{ color: 'green' }}>
                            {paymentDetails.transactionStatus}
                        </div>
                    </div>
                </div>

                <hr className="my-4 border-t border-gray-300" />

                <div className="flex items-center mb-4">
                    <div className="flex-shrink-0">
                        <label className="block text-sm font-medium text-600" style={{ color: 'gray' }}>Username:</label>
                    </div>
                    <div className="ml-auto">
                        <div className="mt-1" >
                            {paymentDetails.payer}
                        </div>
                    </div>
                </div>

                <hr className="my-4 border-t border-gray-300" />

                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <label className="block text-sm font-medium text-600" style={{ color: 'gray' }}>Amount:</label>
                    </div>
                    <div className="ml-auto">
                        <div className="mt-1">
                            {paymentDetails.amount}$
                        </div>
                    </div>
                </div>

                {iscashback && (
                    <div>
                        <hr className="my-4 border-t border-gray-300" />
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <label className="block text-sm font-medium text-600" style={{ color: 'gray' }}>Cashback Recieved:</label>
                            </div>
                            <div className="ml-auto">
                                <div className="mt-1 text-green-600">
                                    +{cashback}$
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>

            <button className="mt-8 bg-600 text-white py-2 px-4 rounded-md" style={{ backgroundColor: '#3c82f6', width: '30%' }} onClick={() => navigate("/payment")}>
                Ok
            </button>
        </div>
    );
};

export default Success;
