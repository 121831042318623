import React, { useEffect, useState } from "react";
import "./Cards.css";
import { cardsData } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import Card from "../Card/Card";


let cardstyle = cardsData.length === 1 ? { margin: '0 20% 0 20%' } : {};
cardstyle = window.innerWidth >= 768 ? { width: '32%', marginLeft: '3%' } : {};

const Cards = () => {
  const navigate = useNavigate();

  const id = localStorage.getItem("id");
  const [loading, setLoading] = useState(true);

  const normalAdminUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/orders`;
  const normalUserUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/orders?customer=${id}`;
  const normalUrl =
    localStorage.getItem("username") === process.env.REACT_APP_ADMIN
      ? normalAdminUrl
      : normalUserUrl;

  const access_token = localStorage.getItem("token");
  const response = localStorage.getItem("totalOrders") ? localStorage.getItem("totalOrders") : null;


  const fetchData = async () => {
    try {
      const totalCountResponse = await axios.get(normalUrl, {
        auth: {
          username: process.env.REACT_APP_CUSTOMER_KEY,
          password: process.env.REACT_APP_CUSTOMER_SECRET
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      const pendingOrdersCount = totalCountResponse.data.reduce(
        (count, order) => (order.status === 'pending' ? count + 1 : count),
        0
      );

      localStorage.setItem("pendingOrdersCount", pendingOrdersCount);
      localStorage.setItem("totalOrders", totalCountResponse.data.length);

    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    } finally {
      setLoading(false);

    }
    window.location.reload();
  };

  useEffect(() => {
    if (response === null) {
      fetchData();
    }
    setLoading(false);
  }, [id, response]);


  const totalOrders = localStorage.getItem("totalOrders");


  const handleCreateOrderClick = () => {
    navigate("/order/new");
  };
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center mt-4">
          <ThreeDots
            height={80}
            width={80}
            radius={9}
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="Cards">
          {totalOrders == "0" || totalOrders == 0 ? (
            <div className="flex flex-col items-center justify-center mx-auto p-8">
              <p className="text-3xl font-semibold text-gray-600 mb-4">No Orders Found</p>
              {/* <button
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                onClick={handleCreateOrderClick}
              >
                Create Order
              </button> */}
            </div>
          ) : (
            cardsData.map((card, id) => (
              <div className="parentContainer" key={id} style={cardstyle}>
                <Card
                  title={card.title}
                  subtitle={card.subtitle}
                  color={card.color}
                  barValue={card.barValue}
                  value={card.value}
                  png={card.png}
                  series={card.series}
                />
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default Cards;


