import React, { useEffect, useState } from "react";
import { UilUsdCircle, UilMoneyInsert, UilPadlock } from '@iconscout/react-unicons';
import axios from "axios";

const Payment = () => {
    const userId = localStorage.getItem("id");
    const [walletBalance, setWalletBalance] = useState(null);
    const [cashback, setCashback] = useState("");
    const [iscashback, setiscashback] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FAST_URL}/payment.php?userId=${userId}`, {
                    method: "GET",
                });

                const data = await response.json();
                if (data) {
                    setWalletBalance(data);
                } else {
                    console.error("Error fetching wallet balance:", data.error);
                }
            } catch (error) {
                console.error("Error fetching wallet balance:", error);
            }
        };

        fetchData();

        // get cashback value
        axios.get(`${process.env.REACT_APP_FAST_URL}/settings.php?type=cashback`).then((response) => {
            console.log(response);
            console.log(response.data.cashback);
            setCashback(response.data.cashback || 0);
            if (response.data.cashback > 0) {
                setiscashback(true);
            }
        }).catch(e => {
            console.log(e)
        })
    }, [userId]);



    return (

        <div className="p-7 max-w-xl mx-auto rounded-lg shadow-2xl mt-5 " style={{ textAlign: 'center' }}>
            <div className="mb-6 bg-white p-4 rounded-md shadow-md">
                <div className="text-3xl font-bold text-blue-500 mb-2">{walletBalance !== null ? `${walletBalance}$` : 'Loading...'}</div>
                <div className="text-lg font-semibold text-gray-700">Current Balance
                    {/* <UilUsdCircle className="inline-block ml-1 mb-1" /> */}
                </div>
            </div>
            {iscashback && (
                <div className="mb-4 text-green-600 font-semibold">
                    Get {cashback}% cashback on recharge!
                </div>
            )}


            <form action={`${process.env.REACT_APP_FAST_URL}/payment.php`} method="post" className="bg-white p-6 rounded-md shadow-md">
                <div className="mb-4">
                    <label className="block text-gray-600 mb-8" style={{ fontSize: '1.55rem' }}>Top up your wallet <UilMoneyInsert className="inline-block ml-1 mb-2" /></label>
                    {/* <p className="text-sm text-gray-500 mb-8">
                        Choose the amount you want to add to your wallet and proceed securely. <UilPadlock className="inline-block " size='18px' />
                    </p> */}
                    <div className="flex items-center">
                        {/* <span className="text-lg font-semibold mr-2">Recharge:</span> */}
                        <input
                            type="text"
                            name="amount"
                            placeholder="Enter amount"
                            className="p-2 border rounded-md focus:outline-none focus:border-blue-500 w-full transition duration-300 ease-in-out"
                            required
                            style={{
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                // borderRadius: '0.375rem',
                                borderRadius: '40px',
                                paddingLeft: '4%'
                            }}
                        />
                    </div>
                    <input type="hidden" name="userId" value={userId} />
                </div>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full transition duration-300 ease-in-out"
                    style={{ borderRadius: '40px', background: 'linear-gradient(to bottom right, #267fbe, #73dff6)' }}
                >
                    Proceed
                </button>
                <p className="text-sm text-gray-500 mt-5 mb-5">
                    Choose the amount you want to add to your wallet and proceed securely. <UilPadlock className="inline-block mb-0.5" size='18px' />
                </p>
            </form>
        </div>

    );
};

export default Payment;
