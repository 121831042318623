export const formatDuration = (isoDuration) => {
  const match = isoDuration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  const hours = match[1] ? parseInt(match[1], 10) : 0;
  const minutes = match[2] ? parseInt(match[2], 10) : 0;
  const seconds = match[3] ? parseInt(match[3], 10) : 0;

  const formattedParts = [];
  if (hours > 0) {
    formattedParts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    formattedParts.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
  }
  if (seconds > 0) {
    formattedParts.push(`${seconds} sec${seconds > 1 ? 's' : ''}`);
  }

  return formattedParts.join(': ');
};
