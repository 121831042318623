import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import MainDash from "./pages/MainDash/MainDash";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Support from "./pages/support";
import OrderForm from "./pages/orderForm";
import Login from "./pages/login";
import OrderTable from "./pages/Orders/orderTable";
import Signup from "./pages/signup";
import AdminLogin from "./pages/adminLogin";
import UserTable from "./pages/Users/userTable";
import ServiceTable from "./pages/Services/serviceTable";
import ServiceCreate from "./pages/Services/serviceCreate";
import ServiceEdit from "./pages/Services/serviceEdit";
import NotFound from "./pages/notFound";
import Settings from "./pages/Settings";
import MyProfile from "./pages/MyProfile";
import Payment from "./pages/Payment/payment";
import Success from "./pages/success";

const isAuthenticated = localStorage.getItem("token");
const isAdmin = localStorage.getItem("username") === process.env.REACT_APP_ADMIN;

const ProtectedRoutes = () => {
  return <Outlet />;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {isAuthenticated ? (
          <div className="AppGlass">
            <Sidebar />
            <div className="overflow-y-auto">
              <Header />
              <Routes>
                {isAdmin ? (
                  <>
                    <Route path="/dashboard" element={<MainDash />} />
                    <Route path="/order/new" element={<OrderForm />} />
                    <Route path="/order-history/:pageSize?/:pageNum?" element={<OrderTable />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/users" element={<UserTable />} />
                    <Route path="/services" element={<ServiceTable />} />
                    <Route path="/service/new" element={<ServiceCreate />} />
                    <Route path="/services/:id/edit" element={<ServiceEdit />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/my-profile" element={<MyProfile />} />
                  </>
                ) : (
                  <>
                    <Route path="/dashboard" element={<MainDash />} />
                    <Route path="/order/new" element={<OrderForm />} />
                    <Route path="/order-history/:pageSize?/:pageNum?" element={<OrderTable />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/my-profile" element={<MyProfile />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/success" element={<Success />} />
                  </>
                )}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login/admin" element={<AdminLogin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/*" element={<Navigate to="/" />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
