import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ServiceEdit() {
    const apiUrl = `${process.env.REACT_APP_FAST_URL}/service.php`;
    const navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState("");
    const [service_id, setService_id] = useState("");
    const [regular_price, setRegular_price] = useState("");
    const [short_description, setShort_description] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const vertical = "top";
    const horizontal = "right";

    const handleClick = () => {
        setOpenSuccess(false);
        setOpenError(false);
    };

    const fetchServiceData = async () => {
        try {
            const response = await axios.get(`${apiUrl}?id=${id}`);
            const serviceData = response.data ? response.data[0] : response.data;

            console.log(serviceData);

            setName(serviceData.name);
            setService_id(serviceData.service_id);
            setRegular_price(serviceData.price);
            setShort_description(serviceData.description);
            setStatus(serviceData.status);
            setType(serviceData.type);
            console.log(serviceData.name);
        } catch (error) {
            console.error("Error fetching service data:", error);
        }
    };

    useEffect(() => {
        fetchServiceData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.put(
                `${apiUrl}?id=${id}`,
                {
                    name,
                    service_id,
                    regular_price,
                    short_description,
                    status,
                    type
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );


            setOpenSuccess(true);
            setTimeout(() => {
                navigate("/services");
            }, 1500);

        } catch (error) {
            console.error("Error updating service:", error);
            setOpenError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center w-full px-10 lg:flex-row">
                <div className="relative z-10 w-full max-w-2xl mt-10 lg:mt-0 lg:w-5/12 rounded-3xl bg-gradient-to-b from-sky-300 to-purple-500 p-px">
                    <div className="relative z-10 flex flex-col items-start justify-start p-10 bg-white rounded-[calc(1.5rem-1px)] shadow-2xl">
                        <h4 className="w-full text-2xl font-medium leading-snug">
                            Edit Service
                        </h4>
                        <form onSubmit={handleSubmit} className="relative w-full mt-6 space-y-8">
                            <div className="relative">
                                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                                    placeholder="Name"
                                />
                            </div>
                            <div className="relative">
                                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                                    Id
                                </label>
                                <input
                                    type="text"
                                    name="service_id"
                                    value={service_id}
                                    onChange={(e) => setService_id(e.target.value)}
                                    className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                                    placeholder="Id"
                                    readOnly
                                />
                            </div>
                            <div className="relative">
                                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                                    Price
                                </label>
                                <input
                                    type="text"
                                    name="regular_price"
                                    value={regular_price}
                                    onChange={(e) => setRegular_price(e.target.value)}
                                    className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                                    placeholder="Price"
                                />
                            </div>
                            <div className="relative">
                                <label className="absolute px-2 ml-2 -mt-3 font-medium text-gray-600 bg-white">
                                    Description
                                </label>
                                <textarea
                                    type="text"
                                    name="short_description"
                                    value={short_description}
                                    onChange={(e) => setShort_description(e.target.value)}
                                    className="block w-full px-4 py-4 mt-2 text-base placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black"
                                    placeholder="Write Description..."
                                />
                            </div>
                            <div className="relative">
                                <button className="inline-block w-100px px-5 py-3 text-lg font-medium text-center bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:bg-blue-700 transition duration-500 text-white rounded-lg ease" disabled={isLoading}>
                                    {isLoading ? (
                                        <div className="  items-center pr-3">
                                            <div className="inline-block animate-spin rounded-full h-6 w-6 border-t-4 border-blue-500 border-solid"></div>
                                        </div>
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Stack spacing={2} sx={{ width: "5%" }}>
                <Snackbar
                    open={openSuccess}
                    autoHideDuration={6000}
                    onClose={handleClick}
                    anchorOrigin={{ vertical, horizontal }}
                    key={vertical + horizontal}
                >
                    <Alert onClose={handleClick} severity="success" sx={{ width: "100%" }}>
                        Service updated successfully!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={openError}
                    autoHideDuration={6000}
                    onClose={handleClick}
                    anchorOrigin={{ vertical, horizontal }}
                    key={vertical + horizontal}
                >
                    <Alert onClose={handleClick} severity="error" sx={{ width: "100%" }}>
                        Error updating service
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

export default ServiceEdit;
