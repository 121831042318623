// MyProfile.js
import React, { useEffect, useState } from "react";
import { UilUserCircle } from "@iconscout/react-unicons";

const MyProfile = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUser = () => {
      const data = {};
      data.name = localStorage.getItem("username");
      data.email = localStorage.getItem("email");
      setUser(data);
    };
    getUser();
  }, []);
  //   const sampleData = {
  //     fullName: "John Doe",
  //     profilePhoto: "url_to_your_profile_photo",
  //     activeFromDate: "2023-01-01", // Replace with the actual date
  //   };

  // Destructuring sample data
  //   const { fullName, profilePhoto, activeFromDate } = sampleData;

  return (
    <div className="p-4 max-w-2xl mx-auto " >
      <h2 className="text-2xl text-gray-600 font-semibold mb-10 w-auto" style={{ textAlign: 'center' }}>My Profile</h2>
      <div className="p-7  mx-auto rounded-lg shadow-xl " style={{ borderRadius: '40px', border: '1px solid cornflowerblue' }}>
        <h2 className="text-2xl mb-10 w-auto" >Personal Information</h2>
        <div className="mb-5">
          <label className="block text-sm mb-3 font-medium text-gray-600">
            Full Name:
          </label>
          <div className="mt-1 p-2 bg-gray-100 rounded-md" style={{ color: 'gray' }}>{user.name}</div>
        </div>
        <div className="mb-5">
          <label className="block text-sm mb-3 font-medium text-gray-600">
            Email:
          </label>
          <div className="mt-1 p-2 bg-gray-100 rounded-md" style={{ color: 'gray' }}>{user.email}</div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
