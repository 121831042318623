import React from "react";
import Cards from "../../components/Cards/Cards";
import "./MainDash.css";
import { useNavigate } from "react-router-dom";


const MainDash = () => {

  const navigate = useNavigate();

  const handleCreateOrderClick = () => {
    navigate("/order/new");
  };

  return (
    <div className="MainDash ml-6 ">
      <h2 className="text-3xl text-gray-600 font-semibold ml-6 mb-4">Dashboard</h2>

      <Cards />
      <div className="flex flex-col items-center justify-center mx-auto p-8 mt-3">
        <p className="text-sm  text-gray-600 mb-4">Explore our services:</p>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-3xl hover:bg-blue-600"
          onClick={handleCreateOrderClick}
          style={{ background: 'linear-gradient(to bottom right, #267fbe, #73dff6)' }}
        >
          Create Order
        </button>
      </div>
    </div >
  );
};

export default MainDash;
