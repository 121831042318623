import React from "react";
import Modal from "react-modal";
import Table, {
  AvatarCell,
  SelectColumnFilter,
  StatusPill
} from "./overview/Table";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UilEdit } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";




const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ServiceTable() {
  // const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/wp-json/wc/v3/products`;
  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_FAST_URL}/service.php`;
  const access_token = localStorage.getItem("token");

  const [serviceDataList, setServiceDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const vertical = "top";
  const horizontal = "right";

  const openModal = (id) => {
    setSelectedDescription(id);
    setIsModalOpen(true);
  };

  const handleClick = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const services = async () => {
    try {
      setIsLoading(true);

      const result = await axios.get(apiUrl, {
        params: {
          per_page: 100
        },
      });

      setServiceDataList(result.data);

      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };



  useEffect(() => {
    services();
  }, []);



  const handleDelete = (id) => {
    axios
      .delete(`${apiUrl}?id=${id}`, {
      })
      .then((response) => {
        console.log(response.data);
        setOpenSuccess(true);
        services();
      })
      .catch((error) => {
        console.log(error);
        setOpenError(true);
      });
  };

  const handleEdit = (id) => {
    const editLink = `/services/${id}/edit`;
    navigate(editLink);
  };



  const getData = () => {

    const mapData = (list) => {
      return list.map((item, index) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        status: item.status,
        price: item.price,
        service_id: item.service_id,
        short_description: item.description,
      }));
    };

    const mappedData = mapData(serviceDataList);

    return mappedData;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: (cell) => {

          return <div>{cell.row.original.service_id}</div>

        }
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Description",
        accessor: "short_description",
        Cell: (cell) => (
          <div>
            <button
              className="bg-blue-400 px-3 py-1 rounded-md text-md text-white"
              onClick={() => openModal(cell.row.original.id)}
            >
              View
            </button>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (cell) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 px-3 py-1 rounded-md text-md text-white"
              onClick={() => handleEdit(cell.row.original.id)}
            >
              <UilEdit size="18" />
            </button>
            <button
              className="bg-red-500 px-3 py-1 rounded-md text-md text-white"
              onClick={() => handleDelete(cell.row.original.id)}
            >
              Delete
            </button>

          </div>
        ),
      },
      //   {
      //     Header: "Currency",
      //     accessor: "currency",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes",
      //   },
    ],
    []
  );

  const data = React.useMemo(() => getData(), [serviceDataList]);

  return (
    <>
      <div className="text-gray-900">
        <main className="w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="flex justify-between">
            <h2 className="text-2xl text-gray-600 font-semibold">
              Services
            </h2>
            <a
              href="/service/new"
              className="px-6 py-2 bg-blue-500 hover:bg-blue-700 active:bg-blue-800 focus:bg-blue-700 transition duration-500 rounded-md text-lg text-white"
            >
              Create
            </a>
          </div>
          {isLoading ? (
            <span className="flex items-center justify-center mt-20">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </span>
          ) : data.length > 0 ? (
            <div className="mt-4">
              <Table columns={columns} data={data} />
            </div>
          ) : (
            <p className="mt-4 text-gray-600 font-medium">Data Not Found</p>
          )}
        </main>
      </div>
      <Stack spacing={2} sx={{ width: "5%" }}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClick}
            severity="success"
            sx={{ width: "100%" }}
          >
            Service deleted successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClick}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClick} severity="error" sx={{ width: "100%" }}>
            Error deleted service
          </Alert>
        </Snackbar>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Service Description Modal"
        className="bg-white w-1/2 mx-auto mt-20 p-6 rounded-md shadow-lg "
        overlayClassName="fixed inset-0 bg-transparent"
      >
        <div className="flex justify-end">
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {selectedDescription && (
          <>
            {/* <h2 className="text-3xl font-bold mb-4">Service Description</h2> */}
            {serviceDataList
              .filter((service) => service.id === selectedDescription)
              .map((service) => (
                <div key={service.id} className="px-4 pb-3">
                  <h2 className="text-3xl font-bold mb-5 ">{service.name}</h2>
                  {/* <h3 className="text-lg font-semibold">{service.name}</h3> */}
                  <p className=" mt-4 mb-3 text-lg text-gray-700">Price: ${service.price}</p>
                  <h2 className="text-gray-700 text-xl font-bold">Description: </h2>
                  <p className="text-gray-500 text-xl whitespace-pre-wrap">{service.description}</p>
                </div>
              ))}
          </>
        )}
      </Modal>


    </>
  );
}

export default ServiceTable;
